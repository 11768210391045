<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">

    <form class="mt-4 w-full" @submit.prevent="submit">
      <div class="form-group" :class="{ 'has-error': hasError('send_at') }">
        <label
          >When to send the emails
          <nl-date-picker v-model="form.send_at" :min-date="minDate" />
        </label>
        <error :error="getError('send_at')" />
      </div>

      <div class="form-group" :class="{ 'has-error': hasError('file') }">
        <label
          >CSV
          <input ref="fileInput" type="file" accept=".csv, text/csv" @change="upload" />
        </label>
        <error :error="getError('file')" />
      </div>

      <hr class="mt-4 mb-6 border-gray-400" />

      <button-loader label="Upload" :loading="submitting" />
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import ButtonLoader from '~/components/global/ButtonLoader'
import { headersFormData, objectToFormData } from '~/utils/form-data'
import HasErrors from '~/mixins/HasErrors'

const TODAY_DATE = new Date() // today

export default {
  components: {
    ButtonLoader,
  },

  mixins: [HasErrors],

  data: () => ({
    form: {
      send_at: TODAY_DATE,
      file: null,
    },
    minDate: TODAY_DATE,
  }),

  methods: {
    clear() {
      this.form = {
        send_at: TODAY_DATE,
        file: null,
      }
      this.$refs.fileInput.value = ''
    },
    dispatchOrderBotSync() {
      axios.post(`/api/shipping/admin/orderbot/sync`)
        .then(() => {
          this.successAlert('OrderBot Sync Is Running in the background')
          this.clear()
        })
        .catch(err => this.errorMessage('Error', 'Failed to run Order Bot Sync'))
    },
    submit() {
      this.wrapSubmit(this.request(this.form))
        .then(() => {
          this.successAlert('The shipment confirmations csv has been uploaded')
          this.clear()
        })
        .catch(err => this.errorMessage('Error', this.$get(err, 'response.data.message')))
    },
    request(form) {
      return axios.post(
        'api/shipping/admin/shipment-confirmations',
        objectToFormData(form),
        headersFormData
      )
    },
    upload(event) {
      this.form.file = event.target.files[0]
    },
  },
}
</script>
